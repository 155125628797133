// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appTitle: 'Prominence Bank Corp',
  // apiEndpoint: 'http://localhost/prominence-bank-api/v1/admin/',
  // apiEndpoint: 'http://dev-my-account-api.prominencebank.com/v1/admin/',
  apiEndpoint: 'https://my-account-api.prominencebank.com/v1/admin/',
  apiRequestHeaders: {
    'Authorization': '0wkckss8c88gcoos0g4c4c80ggoo840gsos4gcsk',
    'Accept': 'application/json'
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
